import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let TurmaAtividadeModule = class TurmaAtividadeModule extends VuexModule {
    async getTurmaAtividades(turmaId) {
        try {
            const response = await api.getTurmaAtividades(mainStore.token, turmaId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurmaAtividadeHoje() {
        try {
            const response = await api.getTurmaAtividadesHoje(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurmaAtividadesByProfessor(professorId) {
        try {
            const response = await api.getTurmaAtividadesByProfessor(mainStore.token, professorId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCurrentDateAtividadeByTurma(turmaId) {
        try {
            const response = await api.getCurrentDateAtividadeByTurma(mainStore.token, turmaId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async UpdateTurmaAtividades(payload) {
        try {
            const response = await api.updateTurmaAtividadeHoje(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async UpdateTurmaAtividade(payload) {
        try {
            const response = await api.updateTurmaAtividade(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getProximaAulaByTumaId(turmaId) {
        try {
            const response = await api.getProximaAulaByTumaId(mainStore.token, turmaId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], TurmaAtividadeModule.prototype, "getTurmaAtividades", null);
__decorate([
    Action
], TurmaAtividadeModule.prototype, "getTurmaAtividadeHoje", null);
__decorate([
    Action
], TurmaAtividadeModule.prototype, "getTurmaAtividadesByProfessor", null);
__decorate([
    Action
], TurmaAtividadeModule.prototype, "getCurrentDateAtividadeByTurma", null);
__decorate([
    Action
], TurmaAtividadeModule.prototype, "UpdateTurmaAtividades", null);
__decorate([
    Action
], TurmaAtividadeModule.prototype, "UpdateTurmaAtividade", null);
__decorate([
    Action
], TurmaAtividadeModule.prototype, "getProximaAulaByTumaId", null);
TurmaAtividadeModule = __decorate([
    Module({ name: 'turmaAtividade' })
], TurmaAtividadeModule);
export default TurmaAtividadeModule;
