import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let ProspexDemonstrativaModule = class ProspexDemonstrativaModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.prospexDemonstrativa = [];
    }
    setProspexsDemonstrativa(payload) {
        this.prospexDemonstrativa = payload;
    }
    async getProspexsDemonstrativaByProspexId(prospex_id) {
        try {
            const response = await api.getProspexsDemonstrativaByProspexId(mainStore.token, prospex_id);
            if (response) {
                this.setProspexsDemonstrativa(response.data);
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], ProspexDemonstrativaModule.prototype, "setProspexsDemonstrativa", null);
__decorate([
    Action
], ProspexDemonstrativaModule.prototype, "getProspexsDemonstrativaByProspexId", null);
ProspexDemonstrativaModule = __decorate([
    Module({ name: 'prospexDemonstrativa' })
], ProspexDemonstrativaModule);
export default ProspexDemonstrativaModule;
