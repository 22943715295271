import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let ContaBancariaModule = class ContaBancariaModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.contasBancarias = [];
    }
    setContaBancarias(payload) {
        this.contasBancarias = payload;
    }
    setContaBancaria(payload) {
        const contasBancarias = this.contasBancarias.filter((company) => company.id !== payload.id);
        contasBancarias.push(payload);
        this.contasBancarias = contasBancarias;
    }
    async createContaBancaria(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createContaBancaria(mainStore.token, payload.conta),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            const contaBancariaId = result[0].data.id;
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Conta Bancária criado com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateContaBancaria(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateContaBancaria(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Conta bancária atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getContasBancarias(is_active = null) {
        try {
            const response = await api.getContasBancarias(mainStore.token, is_active);
            if (response) {
                this.setContaBancarias(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    // nao vai ser usado o query por enquanto
    // @Action
    // public async getContaBancariaMovimentacoes(queryIn) {
    //   try {
    //     const response = await api.getContaBancariaMovimentacoesQuery(mainStore.token, queryIn);
    //     if (response) {
    //       return response.data;
    //     }
    //   } catch (error) {
    //     mainStore.addNotification({
    //       content: error.response.data.detail,
    //       color: 'error',
    //     });
    //     await mainStore.checkApiError(error);
    //   }
    // }
    async getContaBancariaMovimentacoes(payload) {
        try {
            const response = await api.getContaBancariaMovimentacoes(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getContaBancariaMovimentacoesByCaixaId(id) {
        try {
            const response = await api.getContaBancariaMovimentacoesByCaixaId(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async createContaBancariaMovimentacoes(movimentacoes) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createContaBancariaMovimentacoes(mainStore.token, { movimentacoes }),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            const contaBancariaId = result[0].data;
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Movimentação criada com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async createContaBancariaMovimentacoesConferenciaDeCaixa(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createContaBancariaMovimentacoesConferenciaDeCaixa(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Movimentação criada com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getContaBancaria(id) {
        try {
            const response = await api.getContaBancaria(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteContaBancaria(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteContaBancaria(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Conta Bancaria excluida com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], ContaBancariaModule.prototype, "setContaBancarias", null);
__decorate([
    Mutation
], ContaBancariaModule.prototype, "setContaBancaria", null);
__decorate([
    Action
], ContaBancariaModule.prototype, "createContaBancaria", null);
__decorate([
    Action
], ContaBancariaModule.prototype, "updateContaBancaria", null);
__decorate([
    Action
], ContaBancariaModule.prototype, "getContasBancarias", null);
__decorate([
    Action
], ContaBancariaModule.prototype, "getContaBancariaMovimentacoes", null);
__decorate([
    Action
], ContaBancariaModule.prototype, "getContaBancariaMovimentacoesByCaixaId", null);
__decorate([
    Action
], ContaBancariaModule.prototype, "createContaBancariaMovimentacoes", null);
__decorate([
    Action
], ContaBancariaModule.prototype, "createContaBancariaMovimentacoesConferenciaDeCaixa", null);
__decorate([
    Action
], ContaBancariaModule.prototype, "getContaBancaria", null);
__decorate([
    Action
], ContaBancariaModule.prototype, "deleteContaBancaria", null);
ContaBancariaModule = __decorate([
    Module({ name: 'contaBancaria' })
], ContaBancariaModule);
export default ContaBancariaModule;
